import {GoogleMap, InfoWindow, LoadScript, Marker, MarkerClusterer, Polyline} from '@react-google-maps/api';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Directions, Job, Location } from '../../types';
import { DataContext } from 'context/DataContext';

export interface Props {
  [key: string]: any
}

const mapStyles = {
  height: "100%",
  width: "100%"
}

const defaultCenter: Location = {lat: 45.892673, lng: 21.052322};

const generateJobIcon = (index: number): string => {
  let color = "rgb(33, 86, 195)";

  return `data:image/svg+xml;utf-8,  <svg width="36" height="31"  viewBox="0 0 36 31" xmlns="http://www.w3.org/2000/svg"><style>.small { font: 15px sans-serif; fill: white;}</style><path d="M0 6.5C0 3.18629 2.68629 0.5 6 0.5H30C33.3137 0.5 36 3.18629 36 6.5V24.5C36 27.8137 33.3137 30.5 30 30.5H6C2.68629 30.5 0 27.8137 0 24.5V6.5Z" fill="${color}"/><text x="${index < 10 ? 14 : 10}" y="22" class="small">${index}</text></svg>`

}

export const Map = (props: Props) => {
  const {jobs, path, serverJobs} = useContext(DataContext);
  const [center, setCenter] = useState<Location>(defaultCenter)
  const [zoom, setZoom] = useState<number>(8)
  const mapRef = useRef <any> ();

  const loadHandler = (map: any) => {
    console.log("Map loaded")
    mapRef.current = map;
  }

  console.log(path);

  useEffect(() => {
    const filt = jobs.filter(x => x.geocoding);
    if (filt.length && center === defaultCenter) setCenter(filt[0].geocoding!); 
  }, [jobs]);

  const extractPolyline = (it?: Directions): Array<Location> => {
    let ans: Array<Location> = []

    if(!it){
        return ans
    }

    it.legs.forEach((it) => {
        it.steps.forEach((x) => {
            x.polyLine.forEach((p) => {
                ans.push({
                    lat: p[1],
                    lng: p[0]
                })
            })
        })
    })
    return ans;
  }
  const polyLine = useMemo(() => path ? extractPolyline(path): undefined, [path])


  return (
    <LoadScript
      googleMapsApiKey="AIzaSyDOOUrQpG5w2L9Rp0vz2_Ex2XhtqYO92nk">
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={zoom}
        center={center}
        onLoad={loadHandler}
        options={{
            mapTypeControl: false,
        }}
      >
        {path ?   
          <>     
            {serverJobs?.map((x, idx) => (
            <Marker key = {"something"} position={x} icon={{
                anchor: new google.maps.Point(16, 16),
                url: generateJobIcon(idx)
            }}/> ))} 
            <Polyline
              path={polyLine!}
              options={{
                  strokeColor: '#2156C3',
                  strokeOpacity: 1,
                  strokeWeight: 6,
                  geodesic: true,
                  icons: [{
                      icon: {
                          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                          scale: 1,
                          fillColor: "#fff",
                          strokeColor: "#fff"
                      },
                      offset: '100%',
                      repeat: '40px'
                  }]
              }}
            />
          </> : 
          jobs.filter(x => x.geocoding).map(x => <Marker key={x.id} position={x.geocoding!!}/>)
        }
      </GoogleMap>
    </LoadScript>

  );
}
