import { useContext, useState } from 'react';
import { DataContext } from 'context/DataContext';
import { JobCrudGrid } from 'components/CrudGrid/JobGrid';
import { Button } from 'components/Button';
import { apiClient } from 'api/client';

export interface Props {
  next: () => void,
  prev: () => void,
}

export const JobPanel = ({next, prev}: Props) => {
  const {jobs, setJobs} = useContext(DataContext);
  const [loading, setLoading] = useState(false);

  const handleContinue = async () => {
    setLoading(true);
    const jobscopy = [...jobs];
    for (let i = 0; i < jobscopy.length; i++){
      jobscopy[i].geocoding = await apiClient.geocode(jobs[i].address);
      setJobs([...jobscopy]);
    }
    next();
    setLoading(false);
  }

  return (
    <div className = "step job-panel">
      <p className = "panel-header">Step 1: Add Job Information</p>
      <div className = "table-wrapper">
        <JobCrudGrid/>
      </div>
      <div className = "controls">
        {loading ? 
          <Button>Geocoding...</Button> :
          <Button onClick = {handleContinue}>Continue</Button>
        }

      </div>
    </div>
  );
}