import './index.scss';

export interface Props {
  [key: string]: any,
  onClick?: () => void
}

export const Button = (props: Props) => {

  return (
    <div onClick = {props.onClick} className = "button">
      {props.children}
    </div>
  );
}