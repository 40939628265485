import { createContext, useState } from "react";
import { Car, Directions, Job, Location } from "types";

interface Props {
  [key: string]: any,
}

const sampleJobs: Job[] = [
  {
    id: 0,
    address: "Hungary, BUDAPEST VI , SZONDI UTCA 96.",
    weight: 0,
    volume: 0,
    boxes: 0,
    startTime: "07:00",
    endTime: "18:00",
    isNew: false,
  },
  {
    id: 1,
    address: "Hungary, FOT , MORICZ ZSIGMOND U 10.",
    weight: 0,
    volume: 0,
    boxes: 0,
    startTime: "07:00",
    endTime: "18:00",
    isNew: false,
  },
  {
    id: 2,
    address: "Hungary, GYOR , SZIGETHY ATTILA UT 80.",
    weight: 0,
    volume: 0,
    boxes: 0,
    startTime: "07:00",
    endTime: "18:00",
    isNew: false,
  },
  {
    id: 3,
    address: "Hungary, VASVAR , MARCIUS 15 TER 3-4.",
    weight: 0,
    volume: 0,
    boxes: 0,
    startTime: "07:00",
    endTime: "18:00",
    isNew: false,
  },
  {
    id: 4,
    address: "Hungary, BUDAPEST III , HELTAI JENO TER 7-9.",
    weight: 0,
    volume: 0,
    boxes: 0,
    startTime: "07:00",
    endTime: "18:00",
    isNew: false,
  },
  {
    id: 5,
    address: "Hungary, CSEPA , BEKE UTCA 132.",
    weight: 0,
    volume: 0,
    boxes: 0,
    startTime: "07:00",
    endTime: "18:00",
    isNew: false,
  },
  {
    id: 6,
    address: "Hungary, MISKOLC , VOROSMARTY U 74.",
    weight: 0,
    volume: 0,
    boxes: 0,
    startTime: "07:00",
    endTime: "18:00",
    isNew: false,
  },
]

const sampleCars: Car[] = [{
  id: 0,
  address: "Hungary, SZEKKUTAS , BEKE U 1.",
  maxWeight: 1000,
  maxVolume: 90,
  maxBoxes: 1000,
  isNew: false,

}]

export interface DataContext {
  jobs: Job[],
  setJobs: (x: Job[]) => void,
  cars: Car[],
  setCars: (x: Car[]) => void,
  path: undefined | Directions,
  setPath: (x: Directions) => void,
  reset: () => void,
  serverJobs: Location[] | undefined,
  setServerJobs: (x: Location[]) => void,
}

export const DataContext = createContext <DataContext> ({
  jobs: [...sampleJobs],
  setJobs: () => {},
  cars: [...sampleCars],
  setCars: () => {},
  path: undefined,
  setPath: () => {},
  reset: () => {},
  serverJobs: undefined,
  setServerJobs: () => {},
})

const clone = (items: any[]): any[] => items.map((item: any) => Array.isArray(item) ? clone(item) : item);

export const DataProvider = (props: Props) => {
  const [jobs, setJobs] = useState <Job[]> (clone(sampleJobs));
  const [cars, setCars] = useState <Car[]> (clone(sampleCars));
  const [path, setPath] = useState <undefined | Directions> ();
  const [serverJobs, setServerJobs] = useState <undefined | Location[]> ();

  const reset = () => {
    setJobs(clone(sampleJobs.map(x => ({
      ...x,
      geocoding: undefined,
    }))));
    setCars(clone(sampleCars));
    setPath(undefined);
    setServerJobs(undefined);
  }

  return (
    <DataContext.Provider value = {{
      jobs,
      setJobs,
      cars,
      setCars,
      path,
      setPath,
      reset,
      serverJobs,
      setServerJobs
    }}>
      {props.children}
    </DataContext.Provider>
  )
}