import { url } from 'inspector';
import { useState } from 'react';
import './index.scss';
import {ReactComponent as BackIcon} from "assets/backicon.svg";
import { JobPanel } from './JobPanel';
import { CheckPanel } from './CheckPanel';
import { CarPanel } from './CarPanel';
import { CalculatingPanel } from './CalculatingPanel';
import { FinishedPanel } from './FinishedPanel';

export interface Props {
  [key: string]: any
}

const renderComponent = (Component: any, props?: any) => <Component {...props}/>

const StepComponents = [JobPanel, CheckPanel, CarPanel, CalculatingPanel, FinishedPanel];

export const Panel = (props: Props) => {
  const [open, setOpen] = useState (true);
  const [step, setStep] = useState (0);

  const next = () => setStep((step + 1) % StepComponents.length);
  const prev = () => setStep(step - 1);

  return (
    <div className={"outer-menu " + (!open ? "ignore" : "")}>
      <div className={"inner-menu " + (!open ? "closed-menu" : "")}>
        <div className = "outer-image" onClick = {() => setOpen(!open)}>
          <BackIcon id = "closeBtn" className = "closed-btn"/>
        </div>
        <div className={"menu-box"}>
          {renderComponent(StepComponents[step], {prev, next})}
        </div>
      </div>
    </div>

  );
}