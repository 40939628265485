import { useContext, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { DataContext } from 'context/DataContext';
import { Button, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer, GridValueGetterParams } from '@mui/x-data-grid';
import { Job } from 'types';
import { apiClient } from 'api/client';

export const randomId = () => Math.floor(Math.random() * 10000000);

interface EditToolbarProps {
  setJobs: (newJobs: (oldjobs: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setJobs, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setJobs((oldjobs: any) => [...oldjobs, { 
      id, 
      address: "",
      weight: 0,
      volume: 0,
      boxes: 0,
      startTime: "07:00",
      endTime: "18:00",
      isNew: true
    }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'address' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

export const JobCrudGrid = () => {
  const {jobs, setJobs} = useContext(DataContext);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  let s = apiClient;

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setJobs(jobs.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = jobs.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setJobs(jobs.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: Job) => {
    const updatedRow = { ...newRow, isNew: false };
    setJobs(jobs.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: "address",
      headerName: "Address",
      width: 150,
      editable: true,
    }, 
    {
      field: "weight",
      headerName: "Weight (kg) ",
      width: 100,
      editable: true,
    }, 
    {
      field: "volume",
      headerName: "Volume (m3)",
      width: 110,
      editable: true,
    }, 
    {
      field: "boxes",
      headerName: "Boxes",
      width: 80,
      editable: true,
    }, 
    {
      field: "startTime",
      headerName: "Start Time",
      width: 90,
      editable: true,
    }, 
    {
      field: "endTime",
      headerName: "End Time",
      width: 85,
      editable: true,
    }, 
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }: {id: any}) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        // const isInEditMode = false;
    
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
    
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      }
    },
  ]

  return (
    <DataGrid
    rows = {jobs}
    columns={columns}
    disableColumnMenu
    disableColumnFilter
    disableColumnSelector
    // disablejobselectionOnClick
    // density='compact'
    editMode='row'
    rowModesModel={rowModesModel}
    onRowModesModelChange={handleRowModesModelChange}
    onRowEditStop={handleRowEditStop}
    processRowUpdate={processRowUpdate}
    slots={{
      toolbar: EditToolbar,
      pagination: () => <></>
    }}
    slotProps={{
      toolbar: { setJobs, setRowModesModel },
    }}
    initialState={{
      pagination: {
        
        paginationModel: {
          pageSize: 20,
        },
      },
    }}
  />
  )
}
