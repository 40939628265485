import { Footer } from 'components/Footer';
import { Map } from './components/Map';
import './styles/index.scss';
import { Panel } from 'components/Panel';
import { Navbar } from 'components/Navbar';

export interface Props {
  [key: string]: any
}

export const App = (props: Props) => {

  return (
    <div className = "app flex apart">
      <div className = "flex col map-wrapper">
        <Navbar/>
        <Map/>
        <Footer/>
      </div>
      <Panel/>
    </div>
  );
}
