import './index.scss';
import {ReactComponent as Logo} from "assets/logo.svg";
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import TrafficIcon from '@mui/icons-material/Traffic';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonIcon from '@mui/icons-material/Person';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MenuIcon from '@mui/icons-material/Menu';

export interface Props {
  [key: string]: any
}

export const Navbar = (props: Props) => {

  return (
    <div className = "navbar flex center apart">
      <div className = "left flex center">
        <Logo className = "logo"/>
        <div className = "input-wrapper">
          <input/>
          <SearchIcon/>
        </div>
        <div className = "flex col center but ndcenter">
          {/*<DirectionsIcon/>*/}
          <img
              src={"/dispatch.svg"}
          />
          <p>Routing</p>
        </div>
        <div className = "flex col center but ndcenter">
          {/*<TrafficIcon/>*/}
          <img
              src={"/livemap.svg"}
          />
          <p>Free GPS</p>
        </div>
        <div className = "flex col center but ndcenter">
          {/*<FactCheckIcon/>*/}
          <img
              src={"/analytics.svg"}
          />
          <p>Analytics</p>
        </div>
        <div className = "flex col center but ndcenter">
          <MoreHorizIcon/>
          <p>More</p>
        </div>
      </div>
      <div className = "flex center right">
        <div className = "round flex center ndcenter">
          <PersonIcon/>
        </div>
        <div className = "round flex center ndcenter">
          <EmojiEventsIcon/>
        </div>
        <div className = "round flex center ndcenter">
          <MenuIcon/>
        </div>
      </div>
    </div>
  );
}
