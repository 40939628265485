import { useContext, useState } from 'react';
import { DataContext } from 'context/DataContext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { JobCrudGrid } from 'components/CrudGrid/JobGrid';
import { Button } from 'components/Button';
import { apiClient } from 'api/client';
import { CarCrudGrid } from 'components/CrudGrid/CarGrid';

export interface Props {
  next: () => void,
  prev: () => void,
}

export const CarPanel = ({next, prev}: Props) => {

  return (
    <div className = "step">
      <p className = "panel-header">Step 3: Add Car Information</p>
      <div className = "table-wrapper">
        <CarCrudGrid/>
      </div>
      <div className = "flex controls center">
        <div className = "back flex center ndcenter" onClick = {prev}>
          <ChevronLeftIcon/>
        </div>
        <Button onClick = {next}>Continue</Button>
        
      </div>
    </div>
  );
}