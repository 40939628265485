import { useContext, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { DataContext } from 'context/DataContext';
import { Button, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer, GridValueGetterParams } from '@mui/x-data-grid';
import { Car } from 'types';
import { apiClient } from 'api/client';

export const randomId = () => Math.floor(Math.random() * 10000000);

interface EditToolbarProps {
  setCars: (newCars: (oldcars: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setCars, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setCars((oldcars: any) => [...oldcars, { 
      id, 
      address: "",
      maxWeight: 0,
      maxVolume: 0,
      maxBoxes: 0,
      isNew: true
    }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'address' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

export const CarCrudGrid = () => {
  const {cars, setCars} = useContext(DataContext);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  let s = apiClient;

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setCars(cars.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = cars.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setCars(cars.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: Car) => {
    const updatedRow = { ...newRow, isNew: false };
    setCars(cars.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: "address",
      headerName: "Address",
      width: 200,
      editable: true,
    }, 
    {
      field: "maxWeight",
      headerName: "Max Weight (kg) ",
      width: 150,
      editable: true,
    }, 
    {
      field: "maxVolume",
      headerName: "Max Volume (m3)",
      width: 150,
      editable: true,
    }, 
    {
      field: "maxBoxes",
      headerName: "Max Boxes",
      width: 120,
      editable: true,
    }, 
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }: {id: any}) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        // const isInEditMode = false;
    
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
    
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      }
    },
  ]

  return (
    <DataGrid
    rows = {cars}
    columns={columns}
    disableColumnMenu
    disableColumnFilter
    disableColumnSelector
    editMode='row'
    rowModesModel={rowModesModel}
    onRowModesModelChange={handleRowModesModelChange}
    onRowEditStop={handleRowEditStop}
    processRowUpdate={processRowUpdate}
    slots={{
      // toolbar: EditToolbar,
      pagination: () => <></>
    }}
    slotProps={{
      toolbar: { setCars, setRowModesModel },
    }}
    initialState={{
      pagination: {
        
        paginationModel: {
          pageSize: 20,
        },
      },
    }}
  />
  )
}
