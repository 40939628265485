import { useContext, useState } from 'react';
import { DataContext } from 'context/DataContext';
import { JobCrudGrid } from 'components/CrudGrid/JobGrid';
import { Button } from 'components/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export interface Props {
  next: () => void,
  prev: () => void,
}

export const FinishedPanel = ({next, prev}: Props) => {
  const {reset} = useContext(DataContext);

  const handleNext = () => {
    reset();
    next();
  }

  return (
    <div className = "step check-panel finished-panel flex col ">
      <p className = "panel-header">Step 5: Finished</p>
      <div className = "description flex col center">
        <p>Your route has been created, view it on the map.</p>
        <a href = "http://xfleet.io">
          <img src = {require("assets/try.png")}/>
        </a>
      </div>
      <div className = "controls">
        <Button onClick = {handleNext}>Calculate another route</Button>
      </div>
    </div>
  );
}