import moment from "moment";
import { Car, Directions, Job, Location } from "types";

class Api  {
  private url = "";
  private companyId = "4E2NwEqDHpzfuuhNyAcRNQ";
  private carId = "1bda66TBSLOsdL99yZx6gA";
  private token = "";

  constructor(url: string) {
    this.url = url;
  }

  async login (user: string, pass: string) {
    const res = await fetch(`${this.url}/users/me/apiTokens`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Token ${this.token}`
      },
      body: JSON.stringify({
        user,
        pass
      })
    })

    const data = await res.json();
    if (res.status !== 201) { 
      throw new Error(data.message ?? "Error in login");
    }
    this.token = data.token;
  }

  async geocode (address: string): Promise <Location> {
    const res = await fetch(`${this.url}/companies/${this.companyId}/geocode?address=${address}`, {
      method: "GET",
      headers: {
        'Authorization': `Token ${this.token}`
      },
    })

    const data = await res.json();

    if (res.status !== 200) { 
      throw new Error(data.message ?? "Error in geocode");
    }
    data.lng = data.lon;
    delete data.lon;

    return data as Location;
  }

  async getRoute (jobs: Job[], car: Car[]) {
    const serverJobs = jobs.map((x, idx) => ({
      id: idx,
      lat: x.geocoding?.lat,
      lon: x.geocoding?.lng,
      weight: x.weight,
      volume: x.volume,
      pallets: x.boxes,
      serviceTime: 600,
      deliverySchedule: [{
        fromTime: x.startTime,
        toTime: x.endTime,
        weekdays: [1, 2, 3, 4, 5, 6, 7]
      }],
      requiredSkills: [],
      requiredFictiveSkills:[]
    }))
    const startCoord = await this.geocode(car[0].address);
    const params = {
      jobs: serverJobs,
      "depot": {
          "lat": startCoord.lat,
          "lon": startCoord.lng
      },
      "vehicles": [
          {
            "id": this.carId
          }
      ],
      "asyncTask": false,
      "maxRoutes": 1,
      "serviceTime": 5 * 60,
      "optimizeType": "quickest",
      "simulateTime": true,
      "useNewEngine": true,
      "departureTime": moment().hours(7).minutes(0).milliseconds(0).format("YYYY-MM-DDTHH:mm:ssZ"),
      "returnToDepot": false,
      "volumeLoadTime": 0,
      "weightLoadTime": 0,
      "maxJobsPerRoute": 30,
      "palletsLoadTime": 0,
      "useRealDistance": false,
      "maxRouteDuration": 48000000,
      "maxVolumePerRoute": 0,
      "maxWeightPerRoute": 0,
      "pointToPointSpeed": 90,
      "maxPalletsPerRoute": 0,
      "pointToPointTracks": false,
      "vehicleSkillsEnabled": false,
      "clientScheduleEnabled": true,
      "allocateVehiclesWoZone": true,
      "vehicleScheduleEnabled": false,
      "groupClientsPerZoneEnabled": false,
      "vehicleZoneAllocationEnabled": false,
      "vehicleVolumeAllocationEnabled": false,
      "vehicleWeightAllocationEnabled": false,
      "vehiclePalletsAllocationEnabled": false,
      "multiRouteVehicleAllocationEnabled": false
    };

    const res = await fetch(`${this.url}/companies/${this.companyId}/deliveryPlans`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.token}`
      },
      body: JSON.stringify(params)
    })

    const data = await res.json();
    if (res.status !== 201) { 
      throw new Error(data.message ?? "Error in getting route");
    }
    return data.routes[0] as any;
  }
}

const apiClient = new Api("https://app.xfleet.io/api/v1");
apiClient.login("1jameswalk@gmail.com", "test1234"); 
export {apiClient};