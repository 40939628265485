import './index.scss';

export interface Props {
  [key: string]: any
}

export const Footer = (props: Props) => {

  return (
    <div className = "footer flex apart">
      <div className = "flex">
        <a href = "">
          Confidențialitate și module cookie 
        </a>
        <a href = "">
          Juridic
        </a>
        <a href = "">
          Publicitate
        </a>
        <a href = "">
          Ajutor 
        </a>
        <a href = "">
          Părere
        </a>
        <a href = "">
          Gestionați preferințele privind modulele cookie 
        </a>
      </div>
      <div>
        <p>
          © 2023 XFleet
        </p>
      </div>
    </div>
  );
}