import { useContext, useEffect, useState } from 'react';
import { DataContext } from 'context/DataContext';
import { JobCrudGrid } from 'components/CrudGrid/JobGrid';
import { Button } from 'components/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {ReactComponent as Logo} from "assets/calculating.svg"
import { apiClient } from 'api/client';
import { Location } from 'types';


export interface Props {
  next: () => void,
  prev: () => void,
}

export const CalculatingPanel = ({next, prev}: Props) => {
  const {cars, jobs, setPath, setServerJobs} = useContext(DataContext);

  useEffect(() => {
    (async () => {
      const data = await apiClient.getRoute(jobs, cars);
      setPath(data.directions);
      setServerJobs(data.jobs.map((x: any) => ({
        lat: x.lat,
        lng: x.lon
      })));
      next();
    })();
  }, [])

  return (
    <div className = "step calculating-panel flex col ">
      <p className = "panel-header">Step 4: Calculating Best Route</p>
      <div className = "calculating flex col center">
        <Logo className = "calculating-logo"/>
        <p>Please wait while we are calculating the best route...</p>
      </div>
      {/* <div className = "flex controls center">
        <div className = "back flex center ndcenter" onClick = {prev}>
          <ChevronLeftIcon/>
        </div>
        {loading ? 
          <Button>Geocoding...</Button> :
          <Button onClick = {next}>Continue</Button>
        }
      </div> */}
    </div>
  );
}