import { useContext, useState } from 'react';
import { DataContext } from 'context/DataContext';
import { JobCrudGrid } from 'components/CrudGrid/JobGrid';
import { Button } from 'components/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export interface Props {
  next: () => void,
  prev: () => void,
}

export const CheckPanel = ({next, prev}: Props) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className = "step check-panel">
      <p className = "panel-header">Step 2: Check Addresses</p>
      <div className = "description flex col">
        <p>
          Make sure to check that all addresses are properly placed on the map.
        </p>
        <p>
          If some addresses are improperly placed, go back to the previous step and change the respective lines.
        </p>

      </div>
      <div className = "flex controls center">
        <div className = "back flex center ndcenter" onClick = {prev}>
          <ChevronLeftIcon/>
        </div>
        {loading ? 
          <Button>Geocoding...</Button> :
          <Button onClick = {next}>Continue</Button>
        }
      </div>
    </div>
  );
}